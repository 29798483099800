@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'tenda-light';

  src: url(../public/assets/fonts/LatinotypeTrendaLight.otf);
}

@font-face {
  font-family: 'tenda-regular';

  src: url(../public/assets/fonts/LatinotypeTrendaRegular.otf);
}

@font-face {
  font-family: 'tenda-bold';

  src: url(../public/assets/fonts/LatinotypeTrendaBold.otf);
}

@font-face {
  font-family: 'tenda-Semibold';

  src: url(../public/assets/fonts/LatinotypeTrendaSemibold.otf);
}

.tsemibold {
  font-family: tenda-Semibold;
}

.tregular {
  font-family: tenda-regular;
}

.tlight {
  font-family: tenda-light;
}

.tbold {
  font-family: tenda-Bold;
}

/* matrix preloader start here */

.matrix {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
}

.matrix span {
  position: absolute;
  top: 0;
  font-size: 16px;
  font-family: monospace;
  color: #525252;
  animation: matrix-fall 1s linear infinite;
  white-space: nowrap;
}

@keyframes matrix-fall {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

#preloader img {
  position: relative;
  z-index: 10;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* matrix preloader end  here*/
